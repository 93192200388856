<template>
<div class="page">
    <div class="search-wrp">
        <SearchTop />
        <NavBottom />
    </div>
    <div class="tit-top">
        {{typeTexts[typeNum]}}
    </div>
    <div class="abs-nav">
        <ul>
            <li :class="{'on':tabIndex == index,'pre_on':tabIndex-1 == index}" v-for="(area,index) in areaList" :key="index">
                <span @click="repullList(area.id,index)"> {{area.title}} </span>
            </li>
        </ul>
    </div>
    <div class="article-list">
        <template v-if="plist.length==0">
          <div class="no-data">暂无相关数据...</div>
        </template>
        <template v-else>
            <ul>
                <li v-for="(art,index) in plist" :key="index">
                    <router-link :to="'/CatalogDetail/'+art.Security_ID">{{art.Security_Title}}</router-link>
                </li>
                <a href="javascript:void(0);" class="more-data" @click="loadmore">
                    加载更多
                </a>
            </ul>
        </template>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            tabIndex: 0,
            typeNum: 0,
            typeTexts: ["城乡目录", "职工目录"],
            areaList: [],
            area: "",
            pageindex: 1,
            plist: []
        };
    },
    created() {
        this.typeNum = this.$route.params.type;
        console.log(this.typeNum);
        this.getType();

    },
    methods: {
        repullList(id, index) {
            this.pageindex = 1;
            this.showList(id, index)
        },
        getType() {
            this.wpost({
                url: "/Ajax/Member/getsecurityclassifylist",
                data: {
                    id: this.typeNum,
                },
                succ: (data) => {
                    this.types = data.list;
                    console.log(this.types)

                    this.areaList = data.list.map(item => {
                        return {
                            title: item.SecurityClassify_Name,
                            id: item.SecurityClassify_ID
                        }
                    })

                    if (data.list.length > 0) {
                        this.showList(data.list[0].SecurityClassify_ID, 0);
                    } else {
                        this.plist = [];
                    }
                }
            });
        },
        showList(id, index) {
            this.plist = [];
            this.tabIndex = index;
            this.wpost({
                url: "/Ajax/Member/getsecuritylist",
                data: {
                    id: id,
                    pagesize: 20,
                    pageindex: this.pageindex
                },
                succ: (data) => {
                    // console.log("result:",data)
                    this.plist = data.list;
                }
            });
        },

        loadmore() {
            this.wpost({
                url: "/Ajax/Member/getsecuritylist",
                data: {
                    id: this.areaList[this.tabIndex].id,
                    pagesize: 20,
                    pageindex: (this.pageindex += 1)
                },
                succ: (data) => {
                    if (data.list.length > 0) {
                        this.plist = this.plist.concat(data.list);
                    } else {
                        this.pageindex -= 1;
                        alert("暂无更多数据...")
                    }
                }
            });
            console.log("pageindex:", this.pageindex);
        }
    }
};
</script>

<style scoped>
.page {
    background-color: #fff;
    padding-top:calc(25vw + 60px)
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.search-wrp{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
}
.article-list {
    margin-left: 85px;
    font-size: 13px;
    text-align: left;
    top: calc(25vw + 60px);
    min-height: calc(100vh - 25vw - 120px);
    overflow: hidden;
    overflow-y: auto;
}

.article-list li {
    padding: 10px 5px;
    border-bottom: 1px solid #ededed
}

.abs-nav {
    position: fixed;
    left: 0;
    width: 80px;
    font-size: 13px;
    text-align: center;
    top: calc(25vw + 60px);
    height: calc(100vh - 25vw - 120px);
    overflow: hidden;
    overflow-y: auto;
}

.abs-nav li {
    padding: 12px 0;
    background-color: #ececec;
}

.abs-nav li span {
    display: block;
}

.abs-nav li span:active {
    background: none
}

.abs-nav li.on {
    background: #fff;
    border-radius: 0;
}

.abs-nav li.pre_on {
    border-radius: 0 0 8px 0;
}

.abs-nav li.on+li {
    border-radius: 0 8px 0 0;
}

.abs-nav li.on span {
    background-image: linear-gradient(to right, #4692f1, #6466fe);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.search-wrp {
    background-image: linear-gradient(to right, #6466fe, #4692f1);
}

.tit-top {
    letter-spacing: 6px;
    color: #fff;
    text-shadow: 2px 2px 2px #fc7505, -2px 2px 2px #666, -3px 3px 3px #666,
        -4px 4px 4px #666, -5px 5px 5px #666, -6px 6px 6px #666;
    font-size: 27px;
    width: calc(100% - 10px);
    height: 25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    background: url(../assets/article_top_bg.png) no-repeat center center / 100% auto;
    position:fixed;
    z-index: 99;
    left: 0;
    top: 50px;
}
</style>
